import React from 'react'
import styled from'styled-components'

import img1 from '../assets/Nfts/a.png';
import img2 from '../assets/Nfts/b.png';
import img3 from '../assets/Nfts/c.png';
import img4 from '../assets/Nfts/d.png';
import img5 from '../assets/Nfts/e.png';

const Section = styled.section`
width: 100vw;
height: 25rem;
position: relative;
border-top: 2px solid ${props => props.theme.text};
border-top: 2px solid ${props => props.theme.text};

background-color: ${props => `rgba(${props.theme.textRgba}, 0.9)`};

display: flex;
justify-content: center;
align-items: center;

overflow: hidden;

@media (max-width: 48em) {
    height: 15rem;
    flex-direction: column;
}
`;
const ImgContainer = styled.div`
width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

display: flex;
justify-content: center;
align-items: center;
opacity: 0.2;

img{
    width: 15rem;
    height: auto;
}

@media (max-width: 48em) {
    img{
        width: 10rem;
        height: auto;
    }
}
`;
const Title =styled.h1`
font-size: ${props => props.theme.fontxl};
color: ${props => props.theme.body};
padding: 1rem 2rem;
z-index:10;
width: 35%;
text-transform: capitalize;

text-shadow: 1px 1px 2px ${props => props.theme.text};

@media (max-width: 48em) {
    font-size: ${props => props.theme.fontxxl};
    text-align: center;
    width: 35%;
    
}
@media (max-width: 48em) {
    font-size: ${props => props.theme.fontlg};
    padding: 2rem 0;
    width: 100%;
    
}

`;
const BtnContainer = styled.div`
width:15%;
display: flex;
justify-content: flex-end;


@media (max-width: 48em) {
    width: 100%;
    justify-content: center;
   
}

`;
const Quixotic = styled.button`

display: inline-block;
background-color: #FF0000;
color: white;
outline: none;
border: none;
font-weight: 600;
font-size: ${props => props.theme.fontslg};
padding: 1.5rem 3rem;
border-radius: 50px;
cursor: pointer;
transition: all 0.2s ease;
position: relative;

@media (max-width: 48em) {
    padding: 1rem 2rem;
    bottom: 1rem;
}
@media (max-width: 30em) {
    padding: 0.5rem 2rem;
    font-size: ${props => props.theme.fontsm};
}

&:hover{
    transform: scale(0.9);
}

&::after{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${props => props.theme.body};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
}

&:hover::after{
    transofrm: translate(-50%, -50%) scale(1);
}
`;

const Banner = () => {
    return (
        <Section>
            <ImgContainer>
            <img src={img1} alt="Aethernity" />
            <img src={img2} alt="Aethernity" />
            <img src={img3} alt="Aethernity" />
            <img src={img4} alt="Aethernity" />
            <img src={img5} alt="Aethernity" />

            </ImgContainer>
            <Title>Find aethernity NFTs on </Title>
            <BtnContainer> 
            <a href="https://quixotic.io/collection/aethernity" target='_blank' 
            rel="noopener noreferrer"><Quixotic>Quixotic</Quixotic></a>            
            </BtnContainer>
            

            
            
        </Section>
    )
}

export default Banner