import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import React from 'react'

// Backend Import
import { useState } from 'react'
// Backend Import

import Navigation from './components/Navigation';
import Home from './components/sections/Home';
import About from './components/sections/About';
import Team from './components/sections/Team';
import Footer from './components/Footer';
import ScrollToTop from "./components/ScrollToTop";
import Roadmap from "./components/sections/Roadmap";



function App() {

  const [accounts, setAccounts] = useState([]);
  
  return (


    <div className="App">

    <>
      <GlobalStyles /> 
      <ThemeProvider theme={light}>

      <Navigation accounts={accounts} setAccounts={setAccounts} />
      <Home accounts={accounts} setAccounts={setAccounts}/>

      <About />
      <Roadmap />
      <Team />
      <Footer />
      <ScrollToTop />
      </ThemeProvider>
   
    </>

    </div>
     
  );
}

export default App;