import React from 'react'
import styled from 'styled-components'
import Typewriter from 'typewriter-effect';


const Title =styled.h2`
font-size: ${props => props.theme.fontxxxl};
text-transoform: capitalize;
width: 90%;
color: ${props => props.theme.text};
align-self: flex-start;

span{
    text-transform: capitalize;
    font-family:'Akaya Telivigala', cursive;
}
.text-1{
    color: blue;
}
.text-2{
    color: orange;
}
.text-3{
    color: red;
}

@media (max-width: 80em) {
    font-size: ${props => props.theme.fontxl};
}
@media (max-width: 58em) {
    align-self: center;
    text-align: center;
}

@media (max-width: 50m) {
    width: 100%;
}

`;

const SubTitle = styled.h3`
font-size: ${props => props.theme.fontsm};
text-transoform: capitalize;
color: ${props => `rgba(${props.theme.text}, 0.6)`};
font-weight:500;
margin-bottom: 1rem;
margin-top: 1rem;
width: 90%;
align-self: flex-start;

@media (max-width: 40em) {
    font-size: ${props => props.theme.fontxs};
    width: 100%;
}

@media (max-width: 48em) {
    align-self: center;
    text-align: center;
    
}


`

//const ButtonContainer = styled.div`
//width: 80%;
//align-self: flex-start;
//`
const TypeWriterText = () => {

    
    return (
        <>
        <Title>
            aethernity 
            <Typewriter
            options={{
                autoStart: true,
                loop: true,
            }}
  onInit={(typewriter) => {
    typewriter.typeString('<span class="text-1">Optimistic</span>')
    .pauseFor(2000)
    .deleteAll()
    .typeString('<span class="text-2">Layer 2</span>')
    .deleteAll()
    .typeString('<span class="text-3">Optimism</span>')
    .pauseFor(2000)
    .deleteAll()
    .start()
  }}
/>

        </Title>
        <SubTitle>365 NFTs stored on the Optimism Blockchain</SubTitle>

              
        {/* <ButtonContainer>  

        <Button text="Connect Your Wallet" />

        </ButtonContainer> */}

        </>
    )
}

export default TypeWriterText