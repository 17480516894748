import React from 'react'
import styled from 'styled-components'
import Banner from './Banner'
import Logo from './sections/Logo'



import Twitter from '../Icons/Twitter'

import Etherscan from '../Icons/Etherscan.png'
import Gitcoin from '../Icons/Gitcoin.png'
import Optimism from '../Icons/Optimism.png'
import Quixotic from '../Icons/Quixotic.png'

const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
color: ${props => props.theme.text};


display: flex;
/* justify-content: center; */
/* align-items: center; */
flex-direction: column;

`

const Container = styled.div`
width: 75%;
margin: 2rem auto;
display: flex;
justify-content: space-between;
align-items: center;

border-bottom: 1px solid  ${props => props.theme.text};

@media (max-width: 48em) {
    width: 90%;

    h1{
        font-size: ${props => props.theme.fontxl};
    }
}
`

const Left = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 48em) {
    width: 100%;
}
`
const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;

&>*{
    padding-right: 0.5rem;
    transition: all 0.2s ease;

    &:hover{
        transform: scale(1.2);
    }
}
`

const MenuItems =styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;

/* display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-gap: 1rem; */

@media (max-width: 48em) {
    display: none;
}

`

const Item = styled.li`
margin: 0 1rem;
color: ${props => props.theme.text};
cursor: pointer;

&::after{
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: ${props => props.theme.text};
    transition: width 0.3s ease;
}
&:hover::after{
    width: 100%;
    /* padding: 0.3rem; */
}
`

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;

a{
    text-decoration: underline;
}

@media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
    

    span{
        margin-bottom: 0.5rem;
        

    }
}
`
const scrollTo = (id) => {

    let element = document.getElementById(id);

    element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
    })
}

const Footer = () => {
    return (
        <Section>
            <Banner />
            <Container>
                <Left>
                    <Logo />
                    <IconList>
                        
                        <a href="http://twitter.com/travenesia" target='_blank' 
                        rel="noopener noreferrer"><Twitter /></a>
                        <a href="https://optimistic.etherscan.io/address/0xf61ec3b18fe70711a2e8dc52916998eebb851517" target='_blank' 
                        rel="noopener noreferrer"><img src={Etherscan} alt="Etherscan"/></a>
                        <a href="https://gitcoin.co/fund#" target='_blank' 
                        rel="noopener noreferrer"><img src={Gitcoin} alt="Gitcoin Fund"/></a>
                        <a href="https://Optimism.io" target='_blank' 
                        rel="noopener noreferrer"><img src={Optimism} alt="Optimism" /></a>
                        <a href="https://quixotic.io/collection/aethernity" target='_blank' 
                        rel="noopener noreferrer"><img src={Quixotic} alt="Aethernity on Quixotic" /></a>
                        


                        
                    </IconList>
                </Left>
                <MenuItems>
                    <Item onClick={() => scrollTo('navigation')} >Home</Item> 
                    <Item onClick={() => scrollTo('about')} >About</Item>
                    <Item onClick={() => scrollTo('roadmap')} >Roadmap</Item>
                    <Item onClick={() => scrollTo('team')} >Team</Item>

               </MenuItems>
                

            </Container>
            <Bottom>
            <span>
                <center>&copy; {new Date().getFullYear()} Aethernity Collection. <a href="https://creativecommons.org/share-your-work/public-domain/cc0/" target="_blank" rel="noopener noreferrer">©CC0</a></center>
            </span>
            <span>
                Made with &#10084; by <a href="https://twitter.com/travenesia" target="_blank" rel="noopener noreferrer"> Travenesia</a>
            </span>
            </Bottom>
        </Section>
    )
}

export default Footer