const { createAlchemyWeb3 } = require('@alch/alchemy-web3');
const web3 = createAlchemyWeb3(process.env.REACT_APP_API_URL);

const contract = require('../utils/Aethernity.json');
const contractAddress = '0xF61Ec3b18fe70711a2e8dc52916998eEBB851517';
const nftContract = new web3.eth.Contract(contract.abi, contractAddress);

// Contract Methods

export const getTotalSupply = async () => {
  const result = await nftContract.methods.totalSupply().call();
  return result;
};