import React from 'react'
import styled from 'styled-components'
import CoverVideo from '../CoverVideo'
import TypeWriterText from '../TypeWriterText'
import RoundTextBlack from '../../assets/Rounded-Text-Black.png';
import { keyframes } from 'styled-components';

// Backend Code
import { useState, useEffect} from "react";
import { ethers, BigNumber } from 'ethers';
import { Button, Flex, Input, Text } from '@chakra-ui/react';

import aethernity from '../../utils/Aethernity.json';
import { getTotalSupply } from '../../utils/interact';

const aethernityNFTAddress = "0xF61Ec3b18fe70711a2e8dc52916998eEBB851517";

// Backend Code

const Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})`     };
width: 100vw;
position: relative;
background-color: ${props => props.theme.body};
`;
const Container = styled.div`
width:75%;
min-height: 80vh;
margin: 0 auto;

@media (max-width: 64em) {
    width: 85%;
}
@media (max-width: 48em) {
    flex-direction: column;
    width: 100%;


&>*:first-child{
    width: 100%;
    margin-top: 2rem;
}
}


display: flex;
justify-content: center;
align-items: center;
`;
const Boxing =styled.div`
position: absolute;
bottom: 2rem;
right: 25%;
width: 22rem;
height: 12rem;
align-items: center;
display: center;
justify-content: center;


@media (max-width: 48em) {
    position: absolute;
    top: 14rem;
    right: 0rem;
    width: 24rem;
    height: 12rem;

    background-color: ${props => `rgba(${props.theme.bodyRgba},0.85)`};
    backdrop-filter: blur(2px);

    
}
`;
const Box =styled.div`
width:50%;
height: 100%;
display: absolute;
flex-direction: column;
align-items: center;
`;
const Supply =styled.div`
position: absolute;

right: 25%;
top: 15%;
width: 22rem;
height: 22rem;
align-items: center;
display: center;
justify-content: center;

@media (max-width: 48em) {
    position: absolute;
    right: 7%;
    top: 0.5rem;
    width: 22rem;
    align-items: center;
    display: center;
    justify-content: center;
}

`;
const rotate = keyframes`
100%{
    transform: rotate(1turn);
}
`;
const Round = styled.div`
position: absolute;
bottom: 2rem;
right: 90%;
width: 6rem;
height: 6rem;
border: 1px solid ${props => props.theme.text};
border-radius:50%;

img{
    width: 100%;
    height: auto;
    animation: ${rotate} 6s linear infinite reverse ;
}
@media (max-width: 64em) {
    width: 4rem;
    height: 4rem;
    left: none;
    right: 2rem;
    bottom: 100%; 
}
@media (max-width: 48em) {
    right: 1rem;
}
`;
const Circle = styled.span `
width: 3rem;
height: 3rem;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;

position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

font-size: ${props => props.theme.fontxl};

background-color: ${props => props.theme.text};
color: ${props => props.theme.body};

@media (max-width: 64em) {
    width: 2rem;
    height: 2rem;
    right: 1rem;
    font-size: ${props => props.theme.fontlg};
}
`;

const Home = ({ accounts }) => {

    const [mintAmount, setMintAmount ] = useState(1);
    const isConnected = Boolean(accounts[0]); 
    
    const [totalSupply, setTotalSupply] = useState(0); 
    
    async function handleMint() {

        try {
            const { ethereum } = window; 

        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                aethernityNFTAddress,
                aethernity.abi,
                signer
            );

            updateTotalSupply();
            

             console.log("Initialize payment");
                const response = await contract.mint(BigNumber.from(mintAmount), {
                    value: ethers.utils.parseEther((0.015 * mintAmount).toString()),
            });
    
            console.log("Mining... please wait");
            await response.wait();
            
            console.log(`Mined, see transaction: https://optimistic.etherscan.io/${response.hash}`);

            
                 
        } else {
            console.log("Optimism object does not exist");
          }
    
        } catch (err) {
          console.log(err);
        }
    } 

                
    const handleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handleIncrement = () => {
        if (mintAmount >= 10) return;
        setMintAmount(mintAmount + 1);
    };

     useEffect(() => {
        const prepare = async () => {
          
          await updateTotalSupply();
        };
    
        prepare();
      });

      const updateTotalSupply = async () => {
        const mintedCount = await getTotalSupply();
        setTotalSupply(mintedCount);
      }; 

    

   
    return ( 
        <Section id="home">
            <Container>

            <Box><CoverVideo/> </Box>              
            <Box><TypeWriterText/></Box> 

            <Supply>
                <Text
                    borderRadius="5px"
                    color="#FF0000"
                    fontStyle="bold"
                    fontSize="24px"
                    fontFamily="Akaya Telivigala"
                    padding="15px"
                    margin="10px" 
                    align="center"
                
                >   Total Supply {totalSupply} / 365
                </Text>
            </Supply>
            <Boxing>
                

                {isConnected ? (

                <div>
                <Flex align="centerleft" justify="center" Top="50px">
                    <Button 
                        backgroundColor="#FF0000"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1 px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        margin="10px"              
                        onClick={handleDecrement}
                        >
                            -
                    </Button>

                        <Input 
                            readOnly
                            fontFamily="inherit"
                            borderRadius="5px"
                            width="100px"
                            height="45px"
                            textAlign="center"
                            paddingLeft="19px"
                            marginTop="10px"
                            type="number"
                            value={mintAmount}
                        />

                    <Button 
                        backgroundColor="#FF0000"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1 px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        margin="10px"              
                        onClick={handleIncrement}
                        >
                            +
                    </Button>
                    
                    <Button 
                        backgroundColor="#FF0000"
                        borderRadius="5px"
                        boxShadow="0px 2px 2px 1 px #0F0F0F"
                        color="white"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="15px"
                        margin="10px"              
                        onClick={handleMint}
                        
                        > 
                        MINT
                    </Button>
                </Flex>

                        <Text
                            backgroundColor="#000000"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1 px #0F0F0F"
                            color="white"
                            
                            fontFamily="inherit"
                            padding="15px"
                            margin="10px" 
                            align="center"
                            
                            
                        
                        >   Mint Cost Ξ {((0.015 * mintAmount).toFixed(3))} oETH + Gas
                        </Text>

                        

                        <Text
                            
                            color="black"
                            
                            fontFamily="inherit"
                            fontSize="14px"
                            fontStyle="italic"
                            padding="5px"
                            margin="10px" 
                            align="center"
                            
                            
                        
                        >   Max 10 NFTs to Mint per Tx 
                        </Text>

                        
                        
                </div>

                
                
            ) : null}


            </Boxing>
            
            
                
                <Round>
                    <Circle>
                        &#x2193; 
                    </Circle>
                    <img src={RoundTextBlack} alt="NFT" /> 
                </Round>

                

                
            </Container>
        </Section>
    )
}
    

export default Home