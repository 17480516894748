import React from 'react'
import styled from 'styled-components'
import Carousel from '../Carousel'

import Button from '../Button'
import { ThemeProvider } from 'styled-components'
import { dark} from '../../styles/Themes';

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.text};

display: flex;
justify-content: center;
align-items: center;
position: relative;
padding-top: 2rem;
`;
const Container = styled.div`
width:75%;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
    width: 85%;
}

@media (max-width: 64em){
    width: 100%;
    flex-direction: column;

    &>*:last-child(
        width: 80%;
    )
}
@media (max-width: 40em){
    

    &>*:last-child(
        width: 90%;
    )
}
`;
const Box =styled.div`
width:50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
align-items: center;

@media (max-width: 40em){
    min-height: 50vh;
}

`;
const Title = styled.h2`
font-size: ${props => props.theme.fontxxl};
text-transoform: capitalize;
color: ${props => props.theme.body};
align-self: flex-start;
width: 80%;
margin: 0 auto;

@media (max-width: 64em){
    width: 100%;
    text-align: center;
}
@media (max-width: 40em){
    font-size: ${props => props.theme.fontxl};

}
@media (max-width: 30em){
    font-size: ${props => props.theme.fontlg};

}
`;
const SubText = styled.p`
font-size: ${props => props.theme.fontlg};
color: ${props => props.theme.body};
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontmd};
}
@media (max-width: 40em){
    font-size: ${props => props.theme.fontmd};

}
@media (max-width: 30em){
    font-size: ${props => props.theme.fontsm};

}

`;
const SubTextLight = styled.p`
font-size: ${props => props.theme.fontlg};
color: ${props => `rgba(${props.theme.bodyRgba}, 0.6)`};
align-self: flex-start;
width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
    width: 100%;
    text-align: center;
    font-size: ${props => props.theme.fontsm};
}
@media (max-width: 40em){
    font-size: ${props => props.theme.fontsm};

}
@media (max-width: 30em){
    font-size: ${props => props.theme.fontxs};

}
`;
const ButtonContainer = styled.div`
width: 80%;
margin: 1rem auto;
display: flex;
align-self: flex-start;

@media (max-width: 64em){
    width: 100%;


    button {
        margin: 0 auto;
    }

}
`;
const About = () => {
    return (
        <Section id="about">
            <Container>

                <Box> <Carousel /> </Box>
                
                <Box> 
                    <Title>
                    Aethernity on <br /> Optimism 
                    </Title>
                    <SubText>
                    Aethernity are 365 NFTs—unique digital collectibles generated entirely from hand drawing arts by @Travenesia. Aethernity are stored as ERC-721 tokens on the Optimism blockchain and hosted on IPFS.
                    </SubText>
                    <SubTextLight>
                    50% primary sales will proceed to Fund Public Goods on Gitcoin Grants (GR14) June 08-23, 2022. Minters also will get a special POAP as an Early Supporters
                    </SubTextLight>
                    
                    <ButtonContainer>

                    <ThemeProvider theme={dark}>
                    <Button text="Join Our Discord Server" link="https://discord.gg/aethernity" target='_blank' 
                        rel="noopener noreferrer" />
                    </ThemeProvider>

                    </ButtonContainer>



                </Box>
            </Container>
            
        </Section>
    )
}

export default About