import  React from 'react'
import styled from 'styled-components'
import Logo from './sections/Logo'
import { Button } from '@chakra-ui/react';
import { useState } from 'react';


const Section = styled.section`
width: 100vw;
background-color: ${props => props.theme.body};
`
const NavBar = styled.nav`
display: flex;
justify-content: space-between;
align-items: center;

width: 85%;
height: ${props => props.theme.navHeight};
margin: 0 auto;

.mobile{
    display: none;
}

@media (max-width: 64em) {

    .desktop {
        display: none;
    }
    .mobile{
        display: inline-block;
    }
}


`
const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style: none;

@media (max-width: 64em) {
    /* Display 1024px */
    position: fixed;
    top: ${props => props.theme.navHeight};
    
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${props => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 50;
    background-color: ${props => `rgba(${props.theme.bodyRgba},0.85)`};
    backdrop-filter: blur(2px);

    transform: ${props => props.click ? `translateY(0)` : `translateY(1000%)`};
    transition: all 0.3s ease;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`
const MenuItem = styled.li`
margin: 0 1rem;
color: ${props => props.theme.text};
cursor: pointer;

&::after{
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: ${props => props.theme.text};
    transition: width 0.3s ease;
}
&:hover::after{
    width: 100%;
}

@media (max-width: 64em) {
    
    margin: 1rem 0;

    &::after {
        display: none;
    }
}
`
const HamburberMenu = styled.span`
width: 1.5rem;
height: 2px;
background: ${props => props.theme.text};

position: absolute;
top: 2rem;
left: 50%;
transform: ${props => props.click ? `translateX(-50%) rotate(90deg)` : `translateX(-50%) rotate(0)`};

display: none;
justify-content: center;
align-items: center;


cursor: pointer;
transition: all 0.3s ease;

@media (max-width: 64em) {
    /* Display 1024px */
    display: flex;
}

&::after, &::before{
    content: ' ';
    width: ${props => props.click ? `1rem` : `1.5rem`};
    height: 2px;
    right: ${props => props.click ? `-2px` : `0`};
    background: ${props => props.theme.text};
    position: absolute;
}

&::after{
    top: ${props => props.click ? `0.3rem` : `0.5rem`};
    transform: ${props => props.click ? `rotate(-40deg)` : `rotate(0)`};
}
&::before{
    bottom ${props => props.click ? `0.3rem` : `0.5rem`};
    transform: ${props => props.click ? `rotate(40deg)` : `rotate(0)`};
`


const Navigation = ({ accounts, setAccounts }) => {

    const [click, setClick] = useState(false);

    const isConnected = Boolean(accounts[0]);
       

    // Connect Account to Metamask (Optimism Mainnet)
    
    async function connectAccount() {

        const provider = window.ethereum;
        const ethereumChainId = '0xA';

        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts", 
            });
            setAccounts(accounts);
        }; 

        if(!provider){
  
            console.log("Metamask is not installed, please install!");

        } else {
          const chainId = await provider.request({ method: 'eth_chainId' });
          
        if (chainId === ethereumChainId) {
          console.log("Bravo!, you are on the correct network");

        } else {
        console.log("oulalal, switch to the correct network");


        /* if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }; */

        try {
  
            await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: ethereumChainId}],
          });

          console.log("You have succefully switched to Optimism Mainnet")
          
        } catch (switchError) {

                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                console.log("This network is not available in your metamask, please add it")
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                        { chainId: '0xA', 
                        chainName:'Optimism',  
                        rpcUrls:['https://mainnet.optimism.io'],
                        blockExplorerUrls:['https://optimistic.etherscan.io'],
                        nativeCurrency: {
                        symbol:'ETH', // 2-6 characters long
                        decimals: 18
                        }
                        
                        }],
                    });
                    } catch (addError) {
                    // handle "add" error
                    console.log(addError);
                     }
                 }
                
            }
        }
    }

            
            
} 


    
    
    const scrollTo = (id) => {

        let element = document.getElementById(id);

        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        })

        setClick(!click);
    }
    

    return (

        <Section id="navigation">
            <NavBar>
                <Logo />
                <HamburberMenu click={click} onClick={() => setClick(!click)}>
                    &nbsp;
                </HamburberMenu>
                <Menu click={click}>
                    <MenuItem onClick={() => scrollTo('navigation')} >Home</MenuItem> 
                    <MenuItem onClick={() => scrollTo('about')} >About</MenuItem>
                    <MenuItem onClick={() => scrollTo('roadmap')} >Roadmap</MenuItem>
                    <MenuItem onClick={() => scrollTo('team')} >Team</MenuItem>
                    <MenuItem><a href="https://quixotic.io/collection/aethernity" target='_blank' 
                        rel="noopener noreferrer">Quixotic</a></MenuItem>
                    <MenuItem>
                    <div className="mobile">

                        {/* Connect */}
                    {isConnected ? (
                        
                        <Button 
                            backgroundColor="#72CC50"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1 px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="inherit"
                            padding="15px"
                            margin="0 15px"    
                        >   
        
                        {accounts.length > 0 ? (
                                "Welcome:  " +
                                String(accounts).substring(0, 5) +
                                "..." +
                                String(accounts).substring(38)
                                ) : (
                                <span></span>
                                )}
        
                        </Button>      
                            
                        ) : (
                            <Button 
                            backgroundColor="#FF0000"
                            borderRadius="5px"
                            boxShadow="0px 2px 2px 1 px #0F0F0F"
                            color="white"
                            cursor="pointer"
                            fontFamily="inherit"
                            padding="15px"
                            margin="0 15px"              
                            onClick={connectAccount}
                            >
                            Connect Optimism
                            </Button>
                        )}
                        
                    </div>
                    
                    </MenuItem>
                    
                </Menu>

                <div className="desktop">        

                {/* Connect */}
                {isConnected ? (
                    
                <Button 
                    backgroundColor="#FF0000"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1 px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    margin="0 15px"    
                >   

                {accounts.length > 0 ? (
                        "Welcome:  " +
                        String(accounts).substring(0, 5) +
                        "..." +
                        String(accounts).substring(38)
                        ) : (
                        <span></span>
                        )}

                </Button>      
                    
                ) : (
                    <Button 
                    backgroundColor="#FF0000"
                    borderRadius="5px"
                    boxShadow="0px 2px 2px 1 px #0F0F0F"
                    color="white"
                    cursor="pointer"
                    fontFamily="inherit"
                    padding="15px"
                    margin="0 15px"              
                    onClick={connectAccount}
                    >
                    Connect Optimism
                    </Button>
                )}
                
                </div>
                
               
            </NavBar>
        </Section>
        
    );
}


export default Navigation